import { createWebHistory, createRouter } from "vue-router";
import Index from "@/assets/views/Index.vue";
import Login from "@/assets/views/Login.vue";
import RequestOtp from "@/assets/views/RequestOtp.vue";
import SignUp from "@/assets/views/SignUp.vue";
import OtpVerification from "@/assets/views/OtpVerification.vue";
import Facilities from "@/assets/views/Facilities.vue";
import Booking from "@/assets/views/Booking.vue";
import Redeem from "@/assets/views/Redeem.vue";
import PointHistory from "@/assets/views/PointHistory.vue";
import Profile from "@/assets/views/Profile.vue";
import Account from "@/assets/views/Account.vue";
import MyBooking from "@/assets/views/MyBooking.vue";
import BookingHistory from "@/assets/views/BookingHistory.vue";
import Announcement from "@/assets/views/Announcement.vue";
import ForgotPassword from "@/assets/views/ForgotPassword.vue";

const routes = [
    {
        path: "/",
        name: "Index",
        component: Index,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/requestOtp",
        name: "RequestOtp",
        component: RequestOtp,
    },
    {
        path: "/signUp",
        name: "SignUp",
        component: SignUp,
    },
    {
        path: "/otpVerification",
        name: "OtpVerification",
        component: OtpVerification,
    },
    {
        path: "/facilities",
        name: "Facilities",
        component: Facilities,
    },
    {
        path: "/booking",
        name: "Booking",
        component: Booking,
    },
    {
        path: "/redeem",
        name: "Redeem",
        component: Redeem,
    },
    {
        path: "/pointHistory",
        name: "PointHistory",
        component: PointHistory,
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
    },
    {
        path: "/account",
        name: "Account",
        component: Account,
    },
    {
        path: "/myBooking",
        name: "MyBooking",
        component: MyBooking,
    },
    {
        path: "/bookingHistory",
        name: "BookingHistory",
        component: BookingHistory,
    },
    {
        path: "/announcement",
        name: "Announcement",
        component: Announcement,
    },
    {
        path: "/forgotPassword",
        name: "ForgotPassword",
        component: ForgotPassword,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;