<template>
    <div class="booking-history">
        <div class="back" @click="goBack">
            <img src="../img/icon-back.png">
        </div>
        <div class="title">
            {{ $t("Booking History") }}
        </div>
        <div v-if="isLoading" v-loading="loading" class="text-center">
            <div class="spinner-border text-primary" role="status"></div>
        </div>
        <div v-else>
            <div v-if="length > 0" class="booking-list">
                <div class="booking-item" v-for="item in list" :key="item.id">
                    <img :src="getAssetPath(item.facility_type.slug)">
                    <div class="booking-text">
                        <div class="booking-text-title">
                            {{ item.facility_time_slot.facilities.facility_name }} - {{ item.facility_time_slot.facilities.facility_type.name }}
                        </div>
                        <div>
                            {{ item.facility_time_slot.time_slot }}
                        </div>
                        <div>
                            {{ getDate(item.date.date) }}
                        </div>
                        <div v-if="item.player_number > 0">
                            {{ item.player_number}} {{ $t("Person(s)") }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center pt-3" v-else>
                {{ $t("No data found")}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BookingHistory',
        data() {
            return {
                userId: localStorage.getItem("userId"),
                isLoading: true,
                list: [],
                length: 0,
            }
        },
        mounted () {
            this.getList();
        },
        methods: {
            goBack() {
                this.$router.back();
            },
            getAssetPath(slug) {
                var assetPath = "";
                if (slug == 'badminton') {
                    assetPath = require("../img/badminton1.jpg");
                } else if (slug == 'swimming-pool') {
                    assetPath = require("../img/swimming-pool1.jpg");
                } else if (slug == 'basketball') {
                    assetPath = require("../img/basketball1.jpg");
                } else if (slug == 'gym') {
                    assetPath = require("../img/gym1.jpg");
                } else {
                    // default as kids-pool
                    assetPath = require("../img/kids-pool1.jpg");
                }

                return assetPath;
            },
            getDate(date) {
                var arr = date.split('-');
                var formattedDate = arr[2] + '-' + arr[1] + '-' + arr[0];
                return formattedDate;
            },
            getList() {
                this.$api.requestByGet('bookings?app_user_id=' + this.userId).then((result) => {
                    this.isLoading = false;
                    if (result.success) {
                        var list = [];
                        if (result.data.length > 0) {
                            var todayDate = new Date();
                            for (var i = 0; i < result.data.length; i++) {
                                var date = new Date(result.data[i]['date']['date']);
                                if (result.data[i]['status'] != 'cancelled' && todayDate.setHours(0,0,0,0) != todayDate.setHours(0,0,0,0)) {
                                    list.push(result.data[i]);
                                }
                            }
                        }
                        this.list = list;
                        this.length = list.length;
                    } else {
                        this.$message.error(this.$t("Failed, please try again!"));
                    }
                }).catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.message);
                });
            },
        },
    }
</script>

<style lang="scss">
    @import '../styles/bookingHistory.scss';
</style>