<template>
    <div class="requestOtp">
        <div class="mountain-img">
            <img src="../img/mountain.png">
        </div>
        <div class="requestOtp-top">
            <div class="back" @click="goBack">
                <img src="../img/icon-back.png">
            </div>
            <div class="title">
                {{ $t("sign in") }}
            </div>
            <!-- <div class="description">
                {{ $t("Please enter your registered email and we will send you the OTP number to your inbox") }}
            </div> -->
            <div class="email-input">
                <input v-model="email" type="email" class="form-control" :class="{ 'error-input': !isEmailValid }" id="emailInput" :placeholder="$t('your email')">
                <p v-if="!isEmailValid" class="error-text">
                    {{ $t("Please enter a valid email address") }}
                </p>
            </div>
            <div class="password-input">
                <input v-if="showPassword" v-model="password" type="text" class="form-control" :class="{ 'error-input': !isPasswordValid }" :placeholder="$t('your password')">
                <input v-else v-model="password" type="password" class="form-control" :class="{ 'error-input': !isPasswordValid }" :placeholder="$t('your password')">
                <span class="password-icon" @click="toggleShow">
                    <font-awesome-icon v-if="showPassword" :icon="['fas', 'eye']" />
                    <font-awesome-icon v-else :icon="['fas', 'eye-slash']" />
                </span>
                <p v-if="!isPasswordValid" class="error-text">
                    {{ $t("Password must be 6-20 characters") }}
                </p>
            </div>
        </div>
        <div class="requestOtp-bottom">
            <div v-if="isButtonLoading" v-loading="loading" class="request-button">
                <div class="spinner-border" role="status"></div>
            </div>
            <div v-else class="request-button" @click="login">
                {{ $t("sign in") }}
            </div>
            <div class="forgot-pwd" @click="forgotPassword">
                {{ $t("Forgot Password") }}?
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RequestOtp',
        data() {
            return {
                email: '',
                password: '',
                isEmailValid: false,
                isPasswordValid: false,
                isButtonLoading: false,
                showPassword: false,
            }
        },
        methods: {
            goBack() {
                this.$router.back();
            },
            checkEmail(value) {
                const emailPattern = /[a-z0-9!#$%&'*+\=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
                this.isEmailValid = emailPattern.test(value) ? true : false;
            },
            checkPassword(value) {
                this.isPasswordValid = value.length >= 6 && value.length <= 20 ? true : false;
            },
            login() {
                var form = {
                    'email': this.email,
                    'pwd': this.password,
                }
                if (this.isEmailValid && this.isPasswordValid) {
                    this.isButtonLoading = true;
                    this.$api.request('login', form).then((result) => {
                        this.isButtonLoading = false;
                        if (result.success == true) {
                            localStorage.setItem("token", result.data.token);
                            this.$router.push("/");
                            // this.$router.push("/otpVerification");
                        } else {
                            if(result.message) {
                                this.$message.error(result.message);
                            } else {
                                this.$message.error(this.$t("Failed, please try again!"));
                            }
                        }
                    }).catch(error => {
                        this.isButtonLoading = false;
                        this.$message.error(error.message);
                    });
                }
            },
            toggleShow() {
                this.showPassword = !this.showPassword;
            },
            forgotPassword() {
                this.$router.push("/forgotPassword");
            }
        },
        watch: {
            email(value) {
                this.email = value;
                this.checkEmail(value);
            },
            password(value) {
                this.password = value;
                this.checkPassword(value);
            }
        },
    }
</script>

<style lang="scss">
    @import '../styles/requestOtp.scss';
</style>