<template>
    <div v-if="isFacilityLoading" v-loading="loading" class="text-center pt-3">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div v-else class="booking">
        <div class="back" @click="goBack">
            <img src="../img/icon-back.png">
        </div>
        <div class="title">
            {{ $t("Book") }} {{ facility.facility_type.name }}
        </div>
        <div class="description">
            {{ facility.facility_description }}
        </div>
        <div class="booking-carousel">
            <el-carousel arrow="never" trigger="click" interval="4000" pause-on-hover="false" indicator-position="none" height="200px">
                <el-carousel-item v-for="item in getAssetList(facility.facility_type.slug)" :key="item">
                    <div class="booking-carousel-item">
                        <img :src="item">
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div v-if="facility.facility_type.slug == 'swimming-pool'">
            <div class="remark">
                {{ $t("*Please be advised that only proper swimming attire is allowed in the pool area, and individuals not complying with this policy may be asked to leave for the sake of maintaining cleanliness and hygiene.") }}
            </div>
        </div>
        <div class="booking-details">
            <div class="details-title">
                {{ $t("Booking Details") }}
            </div>
            <div class="booking-input">
                <div class="booking-input-title">
                    {{ $t("Select Date") }}
                </div>
                <el-date-picker
                    v-model="date"
                    type="date"
                    :placeholder="$t('Select Date')"
                    format="DD/MM/YYYY"
                    value-format="YYYY-MM-DD"
                    @change="getDate()"
                />
            </div>
            <div class="booking-input">
                <div class="booking-input-title">
                    {{ $t("Select Time") }}
                </div>
                <el-select v-model="time" :placeholder="$t('Select Time')" value-key="id" @change="getTime()">
                    <el-option
                        v-for="(item, key) in timeOption"
                        :key="item.id"
                        :label="key"
                        :value="key"
                    />
                </el-select>
            </div>
            <div v-if="facility.facility_type.slug == 'badminton' || facility.facility_type.slug == 'basketball' || facility.facility_type.slug == 'ktv' || facility.facility_type.slug == 'ping-pong'" class="booking-input">
                <div class="booking-input-title">
                    {{ $t("Select Court") }}
                </div>
                <el-select v-model="court" :placeholder="$t('Select Court')" @change="getCourt">
                    <el-option
                        v-for="item in courtOption"
                        :key="item"
                        :label="item.facility_name"
                        :value="item"
                    />
                </el-select>
                <div class="booking-input-title">
                    {{ $t("No. of Pax") }}
                </div>
                <el-input-number v-model="pax" :min="0" :max="facility.facility_pax" @change="handleChange" />
            </div>
            <div v-else class="booking-input">
                <div class="booking-input-title">
                    {{ $t("No. of Pax") }}
                </div>
                <el-input-number v-model="pax" :min="0" :max="facility.facility_pax" @change="handleChange" />
            </div>
            <div v-if="isBookingLoading" v-loading="loading" class="booking-button">
                <div class="spinner-border" role="status"></div>
            </div>
            <div v-else class="booking-button" @click="booking">
                {{ $t("Book") }}
            </div>
        </div>

        <FailedDialog 
            v-if="isShowFailedDialog"
            :title="failedMsg"
            :desc="$t('Your booking failed, please try again!')"
            @isShowFailedDialog="closeDialog('failed')"
        />

        <SuccessDialog 
            v-if="isShowSuccessDialog"
            :desc="$t('You\'ve successfully booked a facility, see you!')"
            @isShowSuccessDialog="closeDialog('success')"
        />
        
    </div>
</template>

<script>
    import FailedDialog from '../../components/FailedDialog.vue'
    import SuccessDialog from '../../components/SuccessDialog.vue'
    export default {
        name: 'Booking',
        components: {
            FailedDialog,
            SuccessDialog
        },
        data() {
            return {
                id: 0,
                isFacilityLoading: true,
                facility: {},
                dates: [],
                date: '',
                time: '',
                timeOption: [],
                court: '',
                courtOption: [],
                pax: 0,
                isBookingLoading: false,
                isShowFailedDialog: false,
                failedMsg: '',
                isShowSuccessDialog: false,
                successMsg: '',
                facilityId: 0,
            }
        },
        mounted () {
            this.id = this.$route.query.id;
            this.getFacility();
        },
        methods: {
            goBack() {
                this.$router.back();
            },
            getFacility() {
                this.$api.requestByGet('facilities').then((result) => {
                    this.isFacilityLoading = false;
                    if (result.success) {
                        this.facility = result.data.find((element) => element.facility_type_id == this.id);
                        console.log(this.facility);
                    } else {
                        this.$message.error(this.$t("Failed, please try again!"));
                    }
                }).catch(error => {
                    this.isFacilityLoading = false;
                    this.$message.error(error.message);
                });
            },
            getAssetList(slug) {
                var asset = [];
                if (slug == 'badminton') {
                    asset = [require("../img/badminton1.jpg"), require("../img/badminton2.jpg")];
                } else if (slug == 'swimming-pool') {
                    asset = [
                    require("../img/swimming-pool1.jpg"),
                    require("../img/swimming-pool2.jpg")
                    ];
                } else if (slug == 'basketball') {
                    asset = [require("../img/basketball1.jpg"), require("../img/basketball2.jpg")];
                } else if (slug == 'gym') {
                    asset = [require("../img/gym1.jpg"), require("../img/gym2.jpg")];
                } else {
                    // default as kids-pool
                    asset = [require("../img/kids-pool1.jpg"), require("../img/kids-pool2.jpg")];
                }
                return asset;
            },
            getDate() {
                //this.isFacilityLoading = true;
                this.timeOption = [];
                var form = {
                    'facility_type_id': this.id,
                    'date': this.date,
                };
                this.$api.request('facility/unified-facilities-checker', form).then((result) => {
                    //this.isFacilityLoading = false;
                    if (result.success) {
                        this.timeOption = result.data.data;
                    } else {
                        this.$message.error(this.$t("Failed, please try again!"));
                    }
                }).catch(error => {
                    this.isFacilityLoading = false;
                    this.$message.error(error.message);
                });
            },
            getTime() {
                let resultMap = new Map(Object.entries(this.timeOption));
                resultMap.forEach((value, key) => {
                    if (this.time == key) {
                        this.courtOption.push(value[0]);
                    }
                });
                if (this.courtOption.length > 0) {
                    this.facilityId = this.courtOption[0]['facility_id'];
                }
            },
            getCourt(val) {
                this.facilityId = val['facility_id'];
            },
            booking() {
                var array   = this.time.split('-');
                var time    = array[0].replace(/\s/g, '');
                var form    = {
                    'app_user_id': localStorage.getItem("userId"), 
                    'facility_id': this.facilityId, //16
                    'date': this.date, //2024-05-01
                    'start_time': time, //08:00
                    'pax': this.pax, //12
                };
                console.log(form)
                //if (this.time.id && (this.court || this.pax)) {
                    this.isBookingLoading = true;
                    this.$api.request('facility-booking', form).then((result) => {
                        this.isBookingLoading = false;
                        if (result.success == true) {
                            // this.facilityList = result.data;
                            this.successMsg = result.message ? result.message : 'Successfully Booked';
                            this.isShowSuccessDialog = true;
                        } else {
                            // this.failedMsg = result.message;
                            this.failedMsg = 'Facility Full Booked';
                            this.isShowFailedDialog = true;
                            // this.$message.error(this.$t("Failed, please try again!"));
                        }
                    }).catch(error => {
                        this.isBookingLoading = false;
                        this.$message.error(error.message);
                    });
                //}
            },
            closeDialog(type) {
                if (type == 'failed') {
                    this.isShowFailedDialog = false;
                } else if (type == 'success') {
                    this.isShowSuccessDialog = false;
                }
            }
        },
    }
</script>

<style lang="scss">
    @import '../styles/booking.scss';
</style>