<template>
    <div class="bottom-navigation">
        <div class="navigation-item" v-for="(item, index) in list" :key="index" @click="goTo(item.route)">
            <img v-if="item.isActive == true" :src="item.activeImg">
            <img v-else :src="item.img">
            <div class="navigation-item-title">
                {{ item.title }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BottomNavigation',
        data() {
            return {
                list: [
                    {
                        activeImg: require("../assets/img/menu-home-coloured.png"),
                        img: require("../assets/img/menu-home.png"),
                        title: this.$t("Home"),
                        route: '/',
                        isActive: true,
                    },
                    {
                        activeImg: require("../assets/img/menu-facilities-coloured.png"),
                        img: require("../assets/img/menu-facilities.png"),
                        title: this.$t("Facilities"),
                        route: '/facilities',
                        isActive: false,
                    },
                    {
                        activeImg: require("../assets/img/menu-redeem-coloured.png"),
                        img: require("../assets/img/menu-redeem.png"),
                        title: this.$t("Redeem"),
                        route: '/redeem',
                        isActive: false,
                    },
                    {
                        activeImg: require("../assets/img/menu-profile-coloured.png"),
                        img: require("../assets/img/menu-profile.png"),
                        title: this.$t("Profile"),
                        route: '/profile',
                        isActive: false,
                    },
                ],
            }
        },
        methods: {
            goTo(route) {
                this.$router.push(route);
            },
        },
        watch: {
            $route(newValue, oldValue) {
               for (var i = 0; i < this.list.length; i++) {
                    if (window.location.pathname == this.list[i].route) {
                        this.list[i].isActive = true;
                    } else {
                        this.list[i].isActive = false;
                    }
               }
            },
        },
    }
</script>

<style lang="scss">
    @import '../assets/styles/bottomNavigation.scss';
</style>