<template>
    <div class="index">
        <div class="index-first">
            <div v-if="isFirstLoading" v-loading="loading" class="text-center">
                <div class="spinner-border text-primary" role="status"></div>
            </div>
            <div v-else>
                <div class="title">
                    {{ $t("Hi,") }} {{ nickname }}
                </div>
                <div class="description">
                    {{ $t("Thank you for using the Fairy Park Heritage City app. Scroll down below for our latest info and news!") }}
                </div>
            </div>
        </div>
        <div class="highlight">
            <div class="section-title">
                {{ $t("Highlights") }}
            </div>
            <div v-if="isHighlightLoading" v-loading="loading" class="text-center">
                <div class="spinner-border text-primary" role="status"></div>
            </div>
            <div v-else>
                <el-carousel arrow="never" trigger="click" interval="4000" pause-on-hover="false">
                    <el-carousel-item v-for="item in highlightList" :key="item">
                        <div class="highlight-item" @click="goTo(item.external_link)">
                            <img :src="'https://crm.fairypark.asia' + item.image_url">
                            <div class="overlay">
                                <div class="highlight-item-text">
                                    <div class="highlight-item-title">
                                        {{ item.title }}
                                    </div>
                                    <div class="highlight-item-description">
                                        {{ item.description }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                    <img src="https://crm.fairypark.asia/highlight-image/1668582401.jpg" alt="img" class="carouselImg"/>
                </el-carousel>
            </div>
        </div>
        <div class="announcement">
            <div class="section-title">
                {{ $t("Announcements") }}
            </div>
            <div v-if="isAnnouncementLoading" v-loading="loading" class="text-center">
                <div class="spinner-border text-primary" role="status"></div>
            </div>
            <div v-else>
                <div class="announcement-list">
                    <div class="announcement-item" v-for="item in announcementList" :key="item.id" @click="goToAnnouncement(item.id)">
                        <img :src="'https://crm.fairypark.asia' + item.image_url">
                        <div class="announcement-text">
                            <div class="announcement-title">
                                {{ item.title }}
                            </div>
                            <div class="announcement-date">
                                {{ getDate(item.date) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="facility">
            <div class="section-title">
                {{ $t("Popular Facilities") }}
                <div class="show-all" @click="goToFacilities">
                    {{ $t("Show all") }}
                </div>
            </div>
            <div v-if="isFacilityLoading" v-loading="loading" class="text-center">
                <div class="spinner-border text-primary" role="status"></div>
            </div>
            <div v-else>
                <div class="facility-list">
                    <div class="facility-item" v-for="item in facilityList" :key="item.id" @click="goToBooking(item.facility_type_id)">
                        <div class="facility-left">
                            <img :src="getAssetPath(item.facility_type.slug)">
                            <div class="facility-text">
                                <div class="facility-title">
                                    {{ item.facility_name }}
                                </div>
                            </div>
                        </div>
                        <div class="facility-arrow">
                            <el-icon><Right /></el-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Index',
        data() {
            return {
                isFirstLoading: true,
                nickname: '',
                isHighlightLoading: true,
                highlightList: [],
                isAnnouncementLoading: true,
                announcementList: [],
                isFacilityLoading: true,
                facilityList: [],
            }
        },
        mounted () {
            this.init();
        },
        methods: {
            init() {
                if (!localStorage.getItem("token")) {
                    this.$router.replace("/login");
                } else {
                    this.getInfo();
                    this.getHighlight();
                    this.getAnnouncement();
                    this.getFacility();
                }
            },
            getInfo() {
                this.$api.requestByGet('me').then((result) => {
                    this.isFirstLoading = false;
                    if (result.success == true) {
                        localStorage.setItem("userId", result.data.id);
                        this.nickname = result.data.nickname;
                    } else {
                        this.$message.error(this.$t("Failed, please try again!"));
                    }
                }).catch(error => {
                    this.isFirstLoading = false;
                    this.$message.error(error.message);
                });
            },
            getHighlight() {
                this.$api.requestByGet('highlights').then((result) => {
                    this.isHighlightLoading = false;
                    if (result.success == true) {
                        this.highlightList = result.data;
                    } else {
                        this.$message.error(this.$t("Failed, please try again!"));
                    }
                }).catch(error => {
                    this.isHighlightLoading = false;
                    this.$message.error(error.message);
                });
            },
            getAnnouncement() {
                this.$api.requestByGet('announcements').then((result) => {
                    this.isAnnouncementLoading = false;
                    if (result.success == true) {
                        this.announcementList = result.data;
                    } else {
                        this.$message.error(this.$t("Failed, please try again!"));
                    }
                }).catch(error => {
                    this.isAnnouncementLoading = false;
                    this.$message.error(error.message);
                });
            },
            getFacility() {
                this.$api.requestByGet('facility/populars').then((result) => {
                    this.isFacilityLoading = false;
                    if (result.success == true) {
                        this.facilityList = result.data;
                    } else {
                        this.$message.error(this.$t("Failed, please try again!"));
                    }
                }).catch(error => {
                    this.isFacilityLoading = false;
                    this.$message.error(error.message);
                });
            },
            getDate(fullDate) {
                var date = fullDate.split(" ");
                return date[0];
            },
            getAssetPath(slug) {
                var assetPath = "";
                if (slug == 'badminton') {
                    assetPath = require("../img/badminton1.jpg");
                } else if (slug == 'swimming-pool') {
                    assetPath = require("../img/swimming-pool1.jpg");
                } else if (slug == 'basketball') {
                    assetPath = require("../img/basketball1.jpg");
                } else if (slug == 'gym') {
                    assetPath = require("../img/gym1.jpg");
                } else {
                    // default as kids-pool
                    assetPath = require("../img/kids-pool1.jpg");
                }

                return assetPath;
            },
            goTo(route) {
                window.open(route, '_blank');
            },
            goToAnnouncement(id) {
                this.$router.push({path: '/announcement',  query: {'id': id}});
            },
            goToFacilities() {
                this.$router.push("/facilities");
            },
            goToBooking(id) {
                this.$router.push({path: '/booking',  query: {'id': id}});
            }
        },
    }
</script>

<style lang="scss">
    @import '../styles/index.scss';
</style>