<template>
    <div class="my-booking">
        <div class="booking-header">
            <div class="back" @click="goBack">
                <img src="../img/icon-back.png">
            </div>
            <div class="history" @click="goTo('/bookingHistory')">
                {{ $t("History") }}
            </div>
        </div>
        <div class="title">
            {{ $t("Bookings") }}
        </div>
        <div v-if="isLoading" v-loading="loading" class="text-center">
            <div class="spinner-border text-primary" role="status"></div>
        </div>
        <div v-else>
            <div v-if="length > 0" class="booking-list">
                <div class="booking-item" v-for="item in list" :key="item.id">
                    <div class="booking-item-left">
                        <img :src="getAssetPath(item.facility_type.slug)">
                        <div class="booking-text">
                            <div class="booking-text-title">
                                {{ item.facility_time_slot.facilities.facility_name }} - {{ item.facility_time_slot.facilities.facility_type.name }}
                            </div>
                            <div>
                                {{ item.facility_time_slot.time_slot }}
                            </div>
                            <div>
                                {{ getDate(item.date.date) }}
                            </div>
                            <div v-if="item.player_number > 0">
                                {{ item.player_number}} {{ $t("Person(s)") }}
                            </div>
                        </div>
                    </div>
                    <font-awesome-icon icon="fa-regular fa-trash-can" @click="cancel(item.id)" />
                </div>
            </div>
            <div class="text-center pt-3" v-else>
                {{ $t("No data found")}}
            </div>
        </div>
        <el-dialog v-model="isShowCancelDialog" center :show-close="false" :close-on-click-modal="false">
            <div class="desc">
                {{ $t("Are you sure you want to cancel booking?") }}
            </div>
            <div class="btn-row">
                <div class="yes" @click="confirmCancel">
                    {{ $t("Yes") }}
                </div>
                <div class="no" @click="isShowCancelDialog = false">
                    {{ $t("No") }}
                </div>
            </div>
        </el-dialog>

        <FailedDialog 
            v-if="isShowFailedDialog"
            :title="failedMsg"
            :desc="$t('Failed, please try again!')"
            @isShowFailedDialog="closeDialog('failed')"
        />

        <SuccessDialog 
            v-if="isShowSuccessDialog"
            :desc="$t('You\'ve successfully cancel a booking!')"
            @isShowSuccessDialog="closeDialog('success')"
        />
    </div>
</template>

<script>
    import FailedDialog from '../../components/FailedDialog.vue'
    import SuccessDialog from '../../components/SuccessDialog.vue'
    export default {
        name: 'MyBooking',
        components: {
            FailedDialog,
            SuccessDialog
        },
        data() {
            return {
                isLoading: true,
                userId: localStorage.getItem('userId'),
                list: [],
                length: 0,
                isShowCancelDialog: false,
                deleteId: 0,
                isShowSuccessDialog: false,
                isShowFailedDialog: false,
            }
        },
        mounted () {
            this.getList();
        },
        methods: {
            goBack() {
                this.$router.back();
            },
            goTo(route) {
                this.$router.push(route);
            },
            getAssetPath(slug) {
                var assetPath = "";
                if (slug == 'badminton') {
                    assetPath = require("../img/badminton1.jpg");
                } else if (slug == 'swimming-pool') {
                    assetPath = require("../img/swimming-pool1.jpg");
                } else if (slug == 'basketball') {
                    assetPath = require("../img/basketball1.jpg");
                } else if (slug == 'gym') {
                    assetPath = require("../img/gym1.jpg");
                } else {
                    // default as kids-pool
                    assetPath = require("../img/kids-pool1.jpg");
                }

                return assetPath;
            },
            getList() {
                this.$api.requestByGet('bookings?app_user_id=' + this.userId).then((result) => {
                    this.isLoading = false;
                    if (result.success) {
                        var list = [];
                        if (result.data.length > 0) {
                            var todayDate = new Date();
                            for (var i = 0; i < result.data.length; i++) {
                                var date = new Date(result.data[i]['date']['date']);
                                if (result.data[i]['status'] != 'cancelled' && todayDate.setHours(0,0,0,0) == todayDate.setHours(0,0,0,0)) {
                                    list.push(result.data[i]);
                                }
                            }
                        }
                        this.list = list;
                        this.length = list.length;
                    } else {
                        this.$message.error(this.$t("Failed, please try again!"));
                    }
                }).catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.message);
                });
            },
            getDate(date) {
                var arr = date.split('-');
                var formattedDate = arr[2] + '-' + arr[1] + '-' + arr[0];
                return formattedDate;
            },
            cancel(id) {
                this.isShowCancelDialog = true;
                this.deleteId = id;
            },
            confirmCancel() {
                var form = {
                    'booking_id': this.deleteId,
                    'status': 'cancelled',
                };
                this.$api.request('bookings/update-status', form).then((result) => {
                    if (result.success == true) {
                        this.isShowCancelDialog = false;
                        this.isShowSuccessDialog = true;
                    } else {
                        this.isShowFailedDialog = true;
                        this.isShowCancelDialog = false;
                    }
                }).catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.message);
                });
            },
            closeDialog(type) {
                if (type == 'failed') {
                    this.isShowFailedDialog = false;
                } else if (type == 'success') {
                    this.isShowSuccessDialog = false;
                    this.$router.back();
                }
            }
        },
    }
</script>

<style lang="scss">
    @import '../styles/myBooking.scss';
</style>