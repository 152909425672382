import { createApp } from 'vue'
import { createI18n } from "vue-i18n";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './App.vue'
import Api from './common/api';
import Router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(far, fas)

import en from "./assets/translations/en.json";
import zh from "./assets/translations/zh.json";

const i18n = createI18n({
    locale: "en",
    fallbackLocale: "en",
    messages: { en, zh },
  });

const app = createApp(App)
app.config.globalProperties.$api = Api
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(Router)
.use(i18n)
.use(ElementPlus)
.use(Api)
.mount('#app')