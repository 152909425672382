<template>
    <div class="point-history">
        <div class="back" @click="goBack">
            <img src="../img/icon-back.png">
        </div>
        <div class="title">
            {{ $t("Point History") }}
        </div>
        <div v-if="isLoading" v-loading="loading" class="text-center pt-5">
            <div class="spinner-border text-primary" role="status"></div>
        </div>
        <div v-else>
            <div v-if="length > 0" class="history-list">
                <div class="history-item" v-for="item in historyList" :key="item.id">
                    <div class="history-title">
                        {{ item.transaction }}{{ item.point }} {{ $t("Points") }}
                    </div>
                    <div> 
                        {{ item.payment_method }}
                    </div>
                    <div>
                        {{ getDate(item.created_at) }}
                    </div>
                </div>
            </div>
            <div class="text-center pt-5" v-else>
                {{ $t("No data found")}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PointHistory',
        data() {
            return {
                isLoading: true,
                userId: localStorage.getItem("userId"),
                historyList: [],
                length: 0,
            }
        },
        mounted () {
            this.getList();
        },
        methods: {
            getList() {
                this.$api.requestByGet('user/point/' + this.userId).then((result) => {
                    this.isLoading = false;
                    if (result.success) {
                        this.historyList = result.data;
                        this.length = this.historyList.length;
                    } else {
                        this.$message.error(this.$t("Failed, please try again!"));
                    }
                }).catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.message);
                });
            },
            goBack() {
                this.$router.back();
            },
            getDate(date) {
                var formattedDate = date.split("T")[0] + " " + date.split("T")[1].split(".")[0];
                return formattedDate;
            },
        },
    }
</script>

<style lang="scss">
    @import '../styles/pointHistory.scss';
</style>