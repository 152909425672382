<template>
    <div class="forgotPassword">
        <div class="mountain-img">
            <img src="../img/mountain.png">
        </div>
        <div class="forgotPassword-top">
            <div class="back" @click="goBack">
                <img src="../img/icon-back.png">
            </div>
            <div class="title">
                {{ $t("Forgot Password") }}
            </div>
            <div v-if="step == 1">
                <div class="description">
                    {{ $t("Please enter your registered email and we will send you the OTP number to your inbox") }}
                </div>
                <div class="email-input">
                    <input v-model="email" type="email" class="form-control" :class="{ 'error-input': !isEmailValid }" id="emailInput" :placeholder="$t('your email')">
                    <p v-if="!isEmailValid" class="error-text">
                        {{ $t("Please enter a valid email address") }}
                    </p>
                </div>
            </div>
            <div v-if="step == 2">
                <div class="description">
                    {{ $t("A 6 digit code has been sent to ") }}
                    <span class="fw-bold">{{ email }}</span>
                </div>
                <div class="input otp-input">
                    <input v-model="otp" class="form-control" :class="{ 'error-input': !isOtpValid }" id="otpInput" :placeholder="$t('OTP Number')">
                    <p v-if="!isOtpValid" class="error-text">
                        {{ $t("Can\'t be empty") }}
                    </p>
                </div>
                <div class="input password-input">
                    <input v-if="showPassword" v-model="password" type="text" class="form-control" :class="{ 'error-input': !isPasswordValid }" :placeholder="$t('your password')">
                    <input v-else v-model="password" type="password" class="form-control" :class="{ 'error-input': !isPasswordValid }" :placeholder="$t('your password')">
                    <span class="password-icon" @click="toggleShowPassword">
                        <font-awesome-icon v-if="showPassword" :icon="['fas', 'eye']" />
                        <font-awesome-icon v-else :icon="['fas', 'eye-slash']" />
                    </span>
                    <p v-if="!isPasswordValid" class="error-text">
                        {{ $t("Password must be 6-20 characters") }}
                    </p>
                </div>
                <!-- <div class="input password-input">
                    <input v-if="showConfirmPassword" v-model="confirmPassword" type="text" class="form-control" :class="{ 'error-input': !isConfirmPasswordValid }" :placeholder="$t('confirm password')">
                    <input v-else v-model="confirmPassword" type="password" class="form-control" :class="{ 'error-input': !isConfirmPasswordValid }" :placeholder="$t('confirm password')">
                    <span class="password-icon" @click="toggleShowConfirmPassword">
                        <font-awesome-icon v-if="showConfirmPassword" :icon="['fas', 'eye']" />
                        <font-awesome-icon v-else :icon="['fas', 'eye-slash']" />
                    </span>
                    <p v-if="!isConfirmPasswordValid" class="error-text">
                        {{ $t("Passwords do not match") }}
                    </p>
                </div> -->
            </div>
        </div>
        <div class="forgotPassword-bottom">
            <div v-if="step == 1">
                <div v-if="isButtonLoading" v-loading="loading" class="request-button">
                    <div class="spinner-border" role="status"></div>
                </div>
                <div v-else class="request-button" @click="requestOtp">
                    {{ $t("Request OTP") }}
                </div>
            </div>
            <div v-if="step == 2">
                <div v-if="isShowResend" class="resend-otp">
                    {{ $t("Don't receive OTP?") }}
                    <span class="brown" @click="resendOtp">
                        {{ $t("Resend OTP") }}
                    </span>
                </div>
                <div v-else class="resend-otp">
                    {{ $t("Resend OTP in") }}
                    <span class="fw-bold">
                        {{ timerCount }}
                    </span>
                </div>
                <div v-if="isButtonLoading" v-loading="loading" class="request-button">
                    <div class="spinner-border" role="status"></div>
                </div>
                <div v-else class="request-button" @click="resetPassword">
                    {{ $t("Reset Password") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ForgotPassword',
        data() {
            return {
                step: 1,
                email: '',
                otp: '',
                password: '',
                confirmPassword: '',
                isEmailValid: false,
                isButtonLoading: false,
                isOtpValid: false,
                isShowResend: false,
                timerCount: 60,
                isPasswordValid: false,
                isConfirmPasswordValid: false,
                showPassword: false,
                showConfirmPassword: false,
            }
        },
        methods: {
            goBack() {
                this.$router.back();
            },
            checkEmail(value) {
                const emailPattern = /[a-z0-9!#$%&'*+\=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
                this.isEmailValid = emailPattern.test(value) ? true : false;
            },
            requestOtp() {
                var form = {
                    'email': this.email,
                }
                if (this.isEmailValid) {
                    this.isButtonLoading = true;
                    this.$api.request('signin', form).then((result) => {
                        this.isButtonLoading = false;
                        if (result.success == true) {
                            this.step = 2;
                            this.timerCount = 60;
                            this.isShowResend = false;
                            // localStorage.setItem("email", this.email);
                            // this.$router.push("/otpVerification");
                        } else {
                            this.$message.error(this.$t("Failed, please try again!"));
                        }
                    }).catch(error => {
                        this.isButtonLoading = false;
                        this.$message.error(error.message);
                    });
                }
            },
            checkOtp(value) {
                this.isOtpValid = this.otp != '' ? true : false;
            },
            resendOtp() {
                var form = {
                    'email': this.email,
                }
                this.$api.request('signin', form).then((result) => {
                    if (result.success == true) {
                        this.timerCount = 60;
                        this.isShowResend = false;
                    } else {
                        this.$message.error(this.$t("Failed, please try again!"));
                    }
                }).catch(error => {
                    this.isButtonLoading = false;
                    this.$message.error(error.message);
                });
            },
            checkPassword(value) {
                if (value.length >= 6 && value.length <= 20) {
                    this.isPasswordValid = true;
                } else {
                    this.isPasswordValid = false;
                }
            },
            checkConfirmPassword(value) {
                if(this.password == value) {
                    this.isConfirmPasswordValid = true;
                } else {
                    this.isConfirmPasswordValid = false;
                }
            },
            toggleShowPassword() {
                this.showPassword = !this.showPassword;
            },
            toggleShowConfirmPassword() {
                this.showConfirmPassword = !this.showConfirmPassword;
            },
            resetPassword() {
                var form = {
                    'email': this.email,
                    'pwd': this.password,
                    'otp': this.otp,
                }
                if (this.isOtpValid && this.isPasswordValid) {
                    this.isButtonLoading = true;
                    this.$api.request('forgot-pwd', form).then((result) => {
                        this.isButtonLoading = false;
                        if (result.success == true) {
                            this.$router.push("/login");
                        } else {
                            this.$message.error(this.$t("Failed, please try again!"));
                        }
                    }).catch(error => {
                        this.isButtonLoading = false;
                        this.$message.error(error.message);
                    });

                }
            },
        },
        computed: {
            timerCount() {
                return this.timerCount; 
            }
        },
        watch: {
            email(value) {
                this.email = value;
                this.checkEmail(value);
            },
            otp(value) {
                this.otp = value;
                this.checkOtp(value);
            },
            password(value) {
                this.password = value;
                this.checkPassword(value);
            },
            confirmPassword(value) {
                this.confirmPassword = value;
                this.checkConfirmPassword(value);
            },
            timerCount: {
                handler(value) {
                    if (value > 0) {
                        setTimeout(() => {
                            this.timerCount--;
                        }, 1000);
                    } else {
                        this.isShowResend = true;
                    }
                },
                immediate: true,
                deep: true,
            }
        },
    }
</script>

<style lang="scss">
    @import '../styles/forgotPassword.scss';
</style>